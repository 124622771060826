:root {
  --cui-body-font-size: .8125rem;
}

.btn-sm, .form-control, .nav-link {
  font-size: var(--cui-body-font-size);
}

.sidebar { 
    background-color:#0DA65D !important; 
    font-size: 0.8rem; 
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.asterisk { 
  color:red;
}

.table-scroll {
  height:400px;  
  overflow:scroll;
}

.table-scroll thead {
  position: sticky;
  top: 0;
  z-index: 0;
}

.table-report-inspection-width {
  width: 110rem;
}

.table-changelog-inspection-width {
  width: 60rem;
}

.table-report-panen-width {
  width: 80rem;
}

.table-evacuation-width {
  width: 69rem;
}

.map {
  position: relative;
  height: 500px;  /* or as desired */
  width: 100%;  /* This means "100% of the width of its container", the .col-md-8 */
  z-index: 0;
}

.custom-carousel > .carousel-control-prev > .carousel-control-prev-icon {
  background-image: url('../images/icons/prev.svg');
}

.custom-carousel > .carousel-control-next > .carousel-control-next-icon {
  background-image: url('../images/icons/next.svg');
}

.custom-carousel > .carousel-control-prev,
.custom-carousel > .carousel-control-next {
  margin-top: 15px;
  align-items: flex-start;
}

.custom-p02-table > :not(caption) > * > * {
  padding: 0.2rem 0.2rem !important;
}

.custom-font-11 {
  font-size: 11px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.ant-table {
  z-index: 0;
}

.ant-table-thead .ant-table-cell {
  background-color: #dcdfe3;
}

.ant-table-summary .ant-table-cell {
  background-color: #d6ebff;
}